import {createRouter,createWebHistory} from 'vue-router'

import Home from '../components/Home'
import ChooseDate from '../components/ChooseDate'
import FoodMenu from '../components/FoodMenu'
import Checkout from '../components/Checkout'
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'
import Seats from '../components/Seats'
import Orders from '../components/Orders'
import Menu from '../components/Menu'
import Profile from '../components/Profile'



const routes = [
    {
        path : '/',
        name : 'home',
        component : Home
    },
    {
        path : '/choose-date',
        name : 'choose-date',
        component : ChooseDate
    },
    {
        path : '/food-menu',
        name : 'food-menu',
        component : FoodMenu
    },
    {
        path : '/checkout',
        name : 'checkout',
        component : Checkout
    },

    {
        path : '/login',
        name : 'login',
        component : Login
    },

    {
        path : '/dashboard',
        name : 'dashboard',
        component : Dashboard
    },

    {
        path : '/seats',
        name : 'seats',
        component : Seats
    },


    {
        path : '/orders',
        name : 'orders',
        component : Orders
    },

    {
        path : '/menu',
        name : 'menu',
        component : Menu
    },

    {
        path : '/profile',
        name : 'profile',
        component : Profile
    },

    

]


const router = createRouter({
    history : createWebHistory(process.env.BASE_URL),
    routes: routes
})


export default router