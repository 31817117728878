<template>
    <Header />
    <section style="padding-top: 100px;">
        <div class="container">
            <p>Filter</p>
            <form @submit.prevent="filter_dates()">
                <div class="row" style="padding-bottom: 30px;">
                    <div class="col-md-4">
                        <label>From</label>
                        <input type="date" class="form-control" v-model="from">
                    </div>

                    <div class="col-md-4">
                        <label>To</label>
                        <input type="date" class="form-control" v-model="to">
                    </div>

                    <div class="col-md-4" style="padding-top: 30px;">

                        <button class="btn btn-danger" type="button" style="margin-right: 10px;" @click="all_orders()">Reset</button>
                        
                        <button class="btn btn-secondary" type="submit">Search</button>
                    </div>

                </div>

            </form>


            <div style="padding-top:30px;" class="shadow p-3">
                <h3>All Orders</h3>
                <div class="table-responsive" style="width: 100%;">
                <table class="table">

                    <thead>
                        <tr> <th>#</th> <th>Customer</th> <th>Email</th> <th>Phone</th> <th>Seats</th> <th>Seat Cost</th> <th>Food Cost</th> <th>Total Cost</th> <th>Paid</th> <th>Date</th> <th>Action</th> </tr>
                    </thead>

                    <tbody>

                        <tr v-for="order in orders">
                        <td> <button class="btn" @click="get_food(order.token)">{{ order.id }}</button> </td>
                        <td> {{ order.first_name }} </td>
                        <td> {{ order.email }} </td>
                        <td> {{ order.phone }} </td>
                        <td> {{ order.seats }} </td>
                        <td> {{ order.seat_cost }} </td>
                        <td> {{ order.food_cost }} </td>
                        <td> {{ order.total_cost }} </td>
                        <td> {{ order.paid }} </td>
                        <td> {{ order.created }} </td>
                        <td> <button class="btn text-danger" @click="delete_order(order.token)">Remove</button> </td>
                        </tr>

                    </tbody>

                </table>
                </div>
            </div>


        </div>
    </section>

    <div class="menu shadow-lg" v-if="show_modal">

        <div>
            <h3>Food Menu</h3>
        </div>

        <div>

            <table class="table">
                <tbody>
                    <tr v-for="food in food_items">
                        <td>{{ food.description }}  </td>
                        <td>Ksh.{{ food.cost }}  </td>
                    </tr>
                </tbody>
            </table>

            <h3> Guests </h3>

            <table class="table">
                <tbody>
                    <tr v-for="g in guests">
                        <td> <i class="bi bi-check"></i> {{ g.name }}  </td>
                        
                    </tr>
                </tbody>
            </table>
          
        </div>

        <div>
            <button class="btn btn-danger" @click="show_modal=false">Close</button>
        </div>

    </div>
    
</template>

<script>
import axios from 'axios'
import Header from './layouts/Header';
export default{
    name : 'Dashboard',
    components : {Header},
    data (){
        return {
         username : localStorage.getItem('username'),
         orders : [],
         to : '',
         from : '',
         food_items : [],
         show_modal : false,
         guests : []
        }
    },
    methods: {
     async all_orders(){
        const res = await axios.get(this.$store.state.api_url+'api/all-orders').then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.orders = res
     },

     async filter_dates(){
        const res = await axios.post(this.$store.state.api_url+'api/filter-dates',{
            to : this.to,
            from : this.from
        }).then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.orders = res
     },

     async get_food(token){
        const res = await axios.post(this.$store.state.api_url+'api/order-items',{
            token : token,
            
        }).then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.food_items = res
        this.get_guests(token)
        this.show_modal = true
     },

     async get_guests(token){
        const res = await axios.post(this.$store.state.api_url+'api/get-guests',{
            token : token,
            
        }).then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.guests = res
       
     },

     async delete_order(token){
        const res = await axios.post(this.$store.state.api_url+'api/delete-order',{
            token : token,
            
        }).then(function(response){
            return response.data
        }).catch(function(error){

        })
        
       this.all_orders()
     }

    },
    created(){
        this.all_orders()
    }
}
</script>

<style scoped>
.box{
    text-align: center;
    background-color: #E3FFCF;
    font-size: 30px;
}

.menu{
    position: fixed;
    right: 0;
    top: 0;
    padding: 20px;
    background-color: #fff;
    width: 30%;
    
}
</style>