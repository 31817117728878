<template>
    <Header />
    <section style="padding-top: 100px;">
        <div class="container">
          
           <div class="row" style="padding-bottom: 30px;">
            <div class="col-md-2">
                <div class="box shadow-lg">
                <button class="btn btn-lg"> <i class="bi bi-person"></i>  {{ username }}</button>
               
            </div>
            </div>

            <div class="col-md-3">
                    <div class="box shadow-lg">
                        <router-link to="/orders" class="btn btn-lg" style="color: #000;"> <i class="bi bi-grid"></i> Orders</router-link>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="box shadow-lg">
                        <router-link to="/seats" class="btn btn-lg" style="color: #000;"><i class="bi bi-person-plus"></i> Seats</router-link>
                    </div>
                </div>

            <div class="col-md-4">
                <div class="box"> <i class="bi bi-bell text-danger"></i> You have a new feature on the app <router-link to="/menu" class="btn btn-warning">Manage menu</router-link> </div>
            </div>

           </div>
            
        


            <div class="row">

                <div class="col-md-6" style="padding-top: 100px; background-color: #F4F4F4;">

         <div style="text-align: center;">
            <h5> <i class="bi bi-bell"></i> You have {{ orders.length }} new orders</h5>
          <router-link to="/orders" class="btn theme-btn-1">View all</router-link>
         </div>
                </div>

                <div class="col-md-6" style="background-image: url('assets/images/banner-2.jpg'); background-size: cover; min-height: 300px;">


                </div>

            </div>



          <div style="margin-top: 30px; background-color: #F4F4F4; padding: 20px; text-align: center; border-radius: 10px;">
            <p>After every order cycle always make sure you have cleared the seats.By clearing the seats you unable new customers to book the seats</p>
            
            <router-link to="/seats" class="btn theme-btn-1">Clear seats now</router-link>

          </div>


        </div>
    </section>

    
</template>

<script>
import axios from 'axios'
import Header from './layouts/Header';
export default{
    name : 'Dashboard',
    components : {Header},
    data (){
        return {
         username : localStorage.getItem('username'),
         orders : []
        }
    },
    methods: {
     async get_orders(){
        const res = await axios.get(this.$store.state.api_url+'api/get-orders').then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.orders = res
     }
    },
    created(){
        this.get_orders()
    }
}
</script>

<style scoped>
.box{
    padding:20px; background-color: #F4F4F4; border-radius: 10px;
    }
</style>