<template>
    <Header />
    <section style="padding-top: 100px;">
        <div class="container">
          
            <div>
                <h3>Add item to menu</h3>
            </div>

            <form @submit.prevent="new_menu()" enctype="multipart/form-data">
                
                <div class="row">

                    <div class="col-md-4">
                        <input type="file" class="form-control input-design" placeholder="Photo" @change="get_photo">
                    </div>
                    
                    <div class="col-md-4">
                        <input type="text" class="form-control input-design" placeholder="Description" v-model="description">
                    </div>


                    <div class="col-md-4">
                        <input type="text" class="form-control input-design" placeholder="Price" v-model="price">
                    </div>


                    <div class="col-md-2" style="padding-top: 8px;">
                       <button  class="btn theme-btn-1" type="submit">Save item</button>
                    </div>

                </div>

            </form>

            <div style="padding-top: 30px;">
                <h3> <i class="bi bi-grid"></i> Menu items</h3>
            </div>

            <div>
                <table class="table">

                    <thead>
                        <tr> <th>Photo</th> <th>Description</th> <th>Price</th> <th>Action</th> </tr>
                    </thead>

                        <tbody v-for="item in menu">

                            <tr>
                                <td> <button class="btn shadow-lg" @click="menu_details(item.id)"><img  :src="this.$store.state.api_url+'assets/images/menu/'+item.image " style="height: 50px; width: 50px;"> </button> </td>
                                <td> {{ item.description }} </td>
                                <td> Ksh. {{ item.cost }} </td>
                                <td> 
                                    <button class="btn text-success" @click="menu_details(item.id)">Edit</button> 
                                    
                                    <button class="btn text-danger" @click="delete_item(item.id)">Remove</button> </td>
                            </tr>

                        </tbody>

                   

                </table>

            </div>


        </div>
    </section>

    <div class="the-form shadow-lg rounded" v-if="show_form">
        <h3> <i class="bi bi-gear"></i> Edit menu</h3>

        <form @submit.prevent="edit_menu()" enctype="multipart/form-data">
            <div class="row">

                <div class="col-md-4">
                    <label for="">Item photo</label>
                    <input type="file" class="form-control input-design" @change="get_photo">
                </div>

                <div class="col-md-4">
                    <label for="">Item name</label>
                    <input type="text" class="form-control input-design" v-model="description">
                </div>

                <div class="col-md-4">
                    <label for="">Item cost</label>
                    <input type="text" class="form-control input-design" v-model="price">
                </div>

                <div class="col-md-6" style="padding-top: 20px;">
                    <button class="btn theme-btn-1" type="button" @click="show_form=!show_form" style="margin-right: 10px;">Close</button>
                   <button class="btn theme-btn-1" type="submit">Save changes</button>
                </div>

            </div>
        </form>

    </div>

    
</template>

<script>
import axios from 'axios'
import Header from './layouts/Header';
export default{
    name : 'Seats',
    components : {Header},
    data (){
        return {
         username : localStorage.getItem('username'),
         photo : '',
         description : '',
         price : '',
         menu : [],
         show_form : false,
         picked_item : ''
        
        }
    },
    methods: {
        
        get_photo(e){
            this.photo = e.target.files[0] 
        },

        async new_menu(){
             //uploa a file
             const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                //uploa a file

                if(!this.photo){
                    alert('Photo is required')
                    return
                }

                if(!this.description){
                    alert('Description is required')
                    return
                }

                if(!this.price){
                    alert('Price is required')
                    return
                }

            const res = await axios.post(this.$store.state.api_url+'api/new-menu',{
                description : this.description,
                photo : this.photo,
                price : this.price
            },config).then(function(response){
                return response.data
            }).catch(function(error){

            })

            alert('Item added to menu')
            this.photo = ''
            this.description = ''
            this.price = ''
            this.get_menu()
        },

        async get_menu(){
            const res = await axios.get(this.$store.state.api_url+'api/get-menu').then(function(response){
                return response.data
            }).catch(function(error){
                return error
            })

            this.menu = res
        },

        async delete_item(id){
            const res = await axios.post(this.$store.state.api_url+'api/delete-menu',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                return error
            })

            this.get_menu()
        },

        async menu_details(id){
            const res = await axios.post(this.$store.state.api_url+'api/menu-details',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                return error
            })

        res.forEach(data => {
            this.description = data.description
            this.price = data.cost  
          }); 
          this.picked_item = id
          this.show_form = true
           
        },



        async edit_menu(){

             //uploa a file
             const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                //uploa a file

                if(!this.description){
                    alert('Description is required')
                    return
                }

                if(!this.price){
                    alert('Price is required')
                    return
                }

            const res = await axios.post(this.$store.state.api_url+'api/edit-menu',{
                description : this.description,
                price : this.price,
                id : this.picked_item,
                photo : this.photo
            },config).then(function(response){
                return response.data
            }).catch(function(error){

            })
            alert('Changes to menu applied successfully')
            this.get_menu()
            this.show_form = false
        },

        

    },
    created(){  
        this.get_menu()
    }
}
</script>

<style scoped>

.the-form{
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    right: 0;
    padding: 30px;
}

</style>


