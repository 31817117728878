<template>
    <div>
        <div>
                    <img src="assets/images/logo-2.png">
                    </div>
                    <div style="padding-top: 20px;">
                        <h1>Seat Reservation With Entry Fee</h1>
                    </div>
    </div>
</template>

<script>

export default{
    name : 'Banner',
    
}

</script>