<template>
    <section>
        <div class="container">
          
            <div class="row">
                <div class="col-md-3"></div>

                <div class="col-md-6" style="text-align: center; padding-top: 20px; background-color: #fff;">

                    <img src="assets/images/logo-2.png">
                    
                    <div style="padding-top: 20px;">
                        <h1>Login</h1>
                    </div>

                    <form @submit.prevent="login()">
                        <input type="text" class="form-control input-design" placeholder="Username" v-model="username">
                        <input type="password" class="form-control input-design" placeholder="Password" style="margin-top: 20px;" v-model="password">
                        <div style="padding-top: 20px;">
                            <button class="btn theme-btn-1">Login</button>
                        </div>
                    </form>

                </div>

                <div class="col-md-3">
                  
                </div>
            </div>
        </div>
    </section>

    
</template>

<script>
import axios from 'axios'
export default{
    name : 'Home',
    components : {},
    data (){
        return {
          username : '',
          password : ''
        }
    },
    methods: {
      async login(){
        if(!this.username){
            alert('Username is required')
            return
        }

        if(!this.password){
            alert('Password is required')
            return
        }

        const res = await axios.post(this.$store.state.api_url+'api/login-now',{
            username : this.username,
            password : this.password
        }).then(function(response){
           return response.data 
        }).catch(function(error){

        })
      
       if(res=='fail'){
        alert('wrong username or password')
       }else{
        res.forEach(data => {
         localStorage.setItem('username',data.username)  
         this.$router.push('/dashboard')
        });
       }

      }
    }
}
</script>