<template>
    <Header />
    <section style="padding-top: 100px;">
        <div class="container">
          
            <div class="row shadow-lg rounded p-5">

                <div class="col-md-6" style="border-right: solid 1px lightgrey;">
                    <div style="text-align: center;">
                    <h1 style="font-size: 100px;"> <i class="bi bi-person-bounding-box"></i> </h1>
                   
                    <h3> {{ username }} </h3>
                    </div>
                    
                </div>


                <div class="col-md-6">
                    <div>
                     
                        <form @submit.prevent="change_password()">
                            <label for="">New password</label> 
                      <input type="password" class="form-control input-design" v-model="pwd"> 
                      <button style="margin-top: 20px;" class="btn theme-btn-1" type="submit">Save changes</button>
                        </form>

                      
                    </div>
                    
                </div>

            </div>

        </div>
    </section>

    
</template>

<script>
import axios from 'axios'
import Header from './layouts/Header';
export default{
    name : 'Profile',
    components : {Header},
    data (){
        return {
         username : localStorage.getItem('username'),
         pwd : ''
        }
    },
    methods: {

        async change_password(){

            if(!this.pwd){
                alert('Password is required')
                return
            }

            const res  = await axios.post(this.$store.state.api_url+'api/change-password',{
                password : this.pwd
            }).then(function(response){
                return response.data
            }).catch(function(error){
                return error
            })

            alert('Changes saved successfully')
            this.pwd = ''
        }


    },
    created(){
       
        
    }
}
</script>

<style scoped>
.box{
    padding:20px; background-color: #F4F4F4; border-radius: 10px;
    }
</style>
