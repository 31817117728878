<template>
    <Header />
    <section style="padding-top: 100px;">
        <div class="container">
          
           

            <div class="row">

                <div class="col-md-4">
                    <div style="text-align: center;" class="box shadow-lg">
                        <h4>Available seats : {{ seats }}</h4>  
                    </div>
                </div>

                <div class="col-md-4">
                    <div style="text-align: center;" class="box shadow-lg">
                        <h4>Total seats : {{ total_seats }}</h4>  
                    </div>

                </div>

                <div class="col-md-4">
                    <div style="text-align: center;" class="box shadow-lg">
                        <h4>Ordered seats : {{ taken_seats }}</h4>  
                    </div>

                </div>

            </div>

            <div style="padding-top: 30px; text-align: center;">
                <button class="btn theme-btn-1" @click="clear_seats()">Clear seats</button>
            </div>

            <div class="row" style="text-align: center;">

                <div class="col-md-12">
                   <form @submit.prevent="edit_seats()">
                    <label>Edit number of seats</label>
                    <input type="text" class="form-control input-design" v-model="total_seats">

                    <div style="padding-top: 10px;">
                        <button class="btn theme-btn-1" type="submit">Update</button>
                    </div>

                   </form>
                </div>

            </div>
            

        </div>
    </section>

    
</template>

<script>
import axios from 'axios'
import Header from './layouts/Header';
export default{
    name : 'Seats',
    components : {Header},
    data (){
        return {
         username : localStorage.getItem('username'),
         seats : 0,
         total_seats : 0,
         taken_seats : 0
        }
    },
    methods: {
   
     async get_seats(){
        const res = await axios.get(this.$store.state.api_url+'api/get-seats').then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.seats = parseInt(res)
     },

     async all_seats(){
        const res = await axios.get(this.$store.state.api_url+'api/total-seats').then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.total_seats = parseInt(res)
     },

     async ordered_seats(){
        const res = await axios.get(this.$store.state.api_url+'api/ordered-seats').then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.taken_seats = parseInt(res)
     },

     async edit_seats(){
        const res = await axios.post(this.$store.state.api_url+'api/edit-seats',
        {
            seats : this.total_seats
        }
        ).then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.get_seats()
        
     },

     async clear_seats(){
        const res = await axios.post(this.$store.state.api_url+'api/clear-seats').then(function(response){
            return response.data
        }).catch(function(error){

        })
        this.get_seats()

        
     }

    },
    created(){
        this.get_seats()
       this.all_seats()
       this.ordered_seats()
        
    }
}
</script>

<style scoped>
.box{
    padding:20px; background-color: #F4F4F4; border-radius: 10px;
    }
</style>
