<template>
    <section>
        <div class="container">
            <div class="row">
              
                <div class="col-md-12" style="text-align: center; padding-top: 70px;">

                    <Banner />

                    <p>Checkout</p>
                    <p>Total cost: Ksh.{{ total_cost }}</p>
                  
                    <form @submit.prevent="make_payment()">
                        <div class="row">

                            <div class="col-md-6" style="padding-top: 10px;">
                                <input type="text" class="form-control input-design" placeholder="First name" v-model="first_name">
                            </div>

                            <div class="col-md-6" style="padding-top: 10px;">
                                <input type="text" class="form-control input-design" placeholder="Last name" v-model="last_name">
                            </div>

                            <div class="col-md-6" style="padding-top: 10px;">
                                <input type="email" class="form-control input-design" placeholder="Email address" v-model="email">
                            </div>

                            <div class="col-md-6" style="padding-top: 10px;">
                                <input type="text" class="form-control input-design" placeholder="Phone number" v-model="phone">
                            </div>

                            <div class="col-md-12" style="padding-top: 10px;">

                                <div>
                                    <p>Add full names fo guests you are booking for including yours.</p>

                                    <div style="padding-bottom: 20px;">
                                        
                                    <button type="button" @click="show_form=true" class="btn btn-danger">Add guests to table</button>
                                    </div>

                                </div>

                               <button v-if="show_button" class="btn theme-btn-1" type="submit">Make Payment</button>
                               <p v-if="show_text">Please wait...</p>
                            </div>

                        </div>
                    </form>

                </div>
                
            </div>
        </div>

        <div class="moda" v-if="show_form">

            <div class="moda-content">
                <div style="text-align: center;">
                    <h3> <i class="bi bi-person"></i> Add guests to table</h3>
                </div>

                <span v-for="guest in guests">
                     <i class="bi bi-check"></i> {{ guest }},
                </span>

                <form @submit.prevent="add_guest()">
                    <div class="row">
                        <div class="col-md-6">
                           <input class="form-control" placeholder="Enter full name" v-model="guest_name"> 
                        </div>

                        <div class="col-md-6">
                          <button style="width: 100%;" class="btn btn-success" type="submit">Add to list</button>  
                        </div>

                    </div>

                    <div style="text-align: center; padding-top: 30px;">
                        <button class="btn btn-danger" type="button" @click="show_form=false">Close</button>
                    </div>

                </form>

            </div>

        </div>


    </section>



</template>

<script>
import axios from 'axios';
import Banner from './layouts/Banner'

export default{
    name : 'Home',
    components : {Banner},
    data (){
        return {
          total_cost : localStorage.getItem('total_cost'),
          first_name : '',
          last_name : '',
          email : '',
          phone : '',
          cart : [],
          token : 'o'+new Date().getTime(),
          show_button : true,
          show_text : false,
          show_form : false,
          guests : [],
          guest_name : ''
        }
    },
    methods: {

        add_guest(){
            if(!this.guest_name){
                alert('Guest name is required')
                return
            }

            if( this.guests.length >= parseInt(localStorage.getItem('seats'))){
                alert('Number of guests exceeds number of seats')
                return
            }else{
                this.guests = this.guests.concat(this.guest_name)
                 this.guest_name = ''    

            }


            
        },
    
        async make_payment(){
            this.cart = this.$store.state.cart
            if(!this.first_name){
                alert('First name is required')
                return 
            }

            if(!this.last_name){
                alert('Last name is required')
                return 
            }

            if(!this.email){
                alert('Email is required')
                return 
            }

            if(!this.phone){
                alert('Phone is required')
                return 
            }

            if(this.guests.length==0){
                alert('Guest name is required')
                return 
            }

            if( this.guests.length < parseInt(localStorage.getItem('seats')) ){
                alert('Number of guests must be equal to number of seats')
                return
            }



            this.show_button = false
            this.show_text = true
           

            const res = await axios.post(this.$store.state.api_url+'api/checkout',{
               seats : localStorage.getItem('seats'),
             
               cart : this.cart,
               total_cost : localStorage.getItem('total_cost'),
               first_name : this.first_name,
               last_name : this.last_name,
               email : this.email,
               phone : this.phone,
               token : this.token,
               seat_cost : localStorage.getItem('seat_cost'),
               food_cost : localStorage.getItem('food_cost'),
               guests : this.guests

            }).then(function(response){
                console.log(response.data)
            }).catch(function(error){
                console.log(error)
            })
            
            window.location.href = this.$store.state.api_url+'make-payment/'+this.token
        }


        
    },
    created(){
        
    }
}
</script>

